import merge from "lodash/merge";
import get from "lodash/get";
import applyShadow from "@ui/utils/applyShadow";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";

class DefaultThemeProps {
  static cache = {};

  getSelectionBarProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v2: merge(
        {
          spacing: {
            size: {
              xs: "8px",
              m: "16px",
            },
          },
        },
        theme.v2,
        get(theme, "v2.overrides.categoryBar.categoryButton")
      ),
    };
    const categoryImageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: false,
          md: false,
          sm: true,
        },
        backgroundColor: "none",
        minHeight: {
          lg: "0",
          md: "0",
          sm: "0",
        },
        padding: {
          lg: `${theme.v2.spacing(2)} 0`,
          md: `${theme.v2.spacing(2)} 0`,
          sm: `0`,
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v2.typography.title5.family,
          backgroundColor: categoryButtonTheme.v2.color.surface,
          color: categoryButtonTheme.v2.color.onSurface,
          selectedColor: categoryButtonTheme.v2.color.background,
          selectedBackgroundColor: categoryButtonTheme.v2.color.primary,
          fontWeight: categoryButtonTheme.v2.typography.title5.weight,
          padding: {
            lg: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
            md: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
            sm: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
          },
          fontSize: {
            lg: categoryButtonTheme.v2.typography.title5.size.lg,
            md: categoryButtonTheme.v2.typography.title5.size.md,
            sm: categoryButtonTheme.v2.typography.title5.size.sm,
          },
          textTransform: categoryButtonTheme.v2.typography.title5.textCase,
          border: `1px solid ${categoryButtonTheme.v2.color.primary}`,
          selectedBorder: `1px solid ${categoryButtonTheme.v2.color.primary}`,
          borderRadius: categoryButtonTheme.v2.decoration.border.radius.default,
          height: {
            lg: "auto",
            md: "auto",
            sm: "auto",
          },
          boxShadow: props.buttonShadow
            ? applyShadow(get(categoryButtonTheme, "v2.decoration.shadow.s", []))
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v2.decoration.border.radius.default,
        },
      },
    };
  }

  getCategoryBarProps(_theme, props = {}) {
    return this.getSelectionBarProps(_theme, props);
  }

  getProductCardProps(_theme, props = {}) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.potencyTag")),
    };
    const priceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.price")),
    };
    const strikePriceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.strikePrice")),
    };
    const sizesTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.sizes")),
    };

    // if white, remove padding
    const potencyTagsPadding =
      potencyTagTheme.v2.color.base.grey[200] === potencyTagTheme.v2.color.secondary
        ? "0px"
        : "2px 4px";

    return {
      root: {
        ...this.getCardProps(theme).root,
        height: {
          lg: props.mode === "small" ? "310px" : "370px",
          md: props.mode === "small" ? "310px" : "370px",
          sm: props.mode === "small" ? "310px" : "370px",
        },
        maxWidth: {
          lg: "280px",
          md: "25vw",
          sm: "50vw",
        },
        minWidth: {
          lg: props.mode === "small" ? "160px" : "150px",
          md: props.mode === "small" ? "160px" : "150px",
          sm: props.mode === "small" ? "100%" : "130px",
        },
      },
      image: {
        borderBottom: "1px solid #f8f8f8",
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: props.mode === "small" ? "155px" : "175px",
          md: props.mode === "small" ? "155px" : "175px",
          sm: props.mode === "small" ? "155px" : "175px",
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
      flowerTypeIndicator: {
        margin: {
          lg: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          md: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          sm: props.mode === "small" ? "0px 0px 0 30px" : "0",
        },
      },
      saleIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        fontFamily: flowerTypeIndicatorTheme.v2.typography.bodyText2.family,
        backgroundColor: "#e80c17",
        fontSize: flowerTypeIndicatorTheme.v2.typography.sizing.s,
        top: 0,
      },
      info: {
        padding: {
          lg: "8px 8px 0 8px",
          md: "8px 8px 0 8px",
          sm: "8px 8px 0 8px",
        },
        childrenMarginBottom: {
          lg: "4px",
          md: "4px",
          sm: "4px",
        },
      },
      brandName: {
        color: brandNameTheme.v2.color.onPrimary,
        fontFamily: brandNameTheme.v2.typography.bodyText2.family,
        fontSize: brandNameTheme.v2.typography.sizing.xs,
        fontWeight: brandNameTheme.v2.typography.body.weight.medium,
        textTransform: "uppercase",
        letterSpacing: "1px",
      },
      productName: {
        color: "#333333",
        fontFamily: productNameTheme.v2.typography.bodyText2.family,
        fontSize: productNameTheme.v2.typography.sizing.m,
        fontWeight: productNameTheme.v2.typography.body.weight.default,
        maxLines: 2,
      },
      potencyTag: {
        fontFamily: potencyTagTheme.v2.typography.bodyText2.family,
        backgroundColor: potencyTagTheme.v2.color.secondary,
        border:
          potencyTagTheme.v2.color.base.grey[200] === potencyTagTheme.v2.color.secondary
            ? 0
            : `1px solid ${potencyTagTheme.v2.color.base.grey[200]}`,
        borderRadius: "12px",
        fontWeight: potencyTagTheme.v2.typography.body.weight.default,
        fontSize: potencyTagTheme.v2.typography.sizing.xs,
        color: potencyTagTheme.v2.color.onPrimary,
        padding: {
          lg: potencyTagsPadding,
          md: potencyTagsPadding,
          sm: potencyTagsPadding,
        },
      },
      productSizes: {
        color: "#727272",
        fontFamily: sizesTheme.v2.typography.bodyText2.family,
        fontSize: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
        fontWeight: sizesTheme.v2.typography.body.weight.default,
      },
      actionsContainer: {
        padding: {
          lg: "0 8px 8px 8px",
          md: "0 8px 8px 8px",
          sm: "0 8px 8px 8px",
        },
      },
      actions: {
        root: {
          fontFamily: actionsTheme.v2.typography.bodyText2.family,
          fontWeight: actionsTheme.v2.typography.title6.weight,
        },
        actions: {
          backgroundColor: actionsTheme.v2.color.primary,
          color: "#ffffff",
          borderRadius: "40px",
          height: {
            lg: "36px",
            md: "36px",
            sm: "36px",
          },
        },
        prices: {
          position: {
            lg: "top",
            md: "top",
            sm: "top",
          },
          crossed: {
            fontSize: strikePriceTheme.v2.typography.sizing.m,
            fontWeight: strikePriceTheme.v2.typography.body.weight.default,
            color:
              props.mode === "small"
                ? strikePriceTheme.v2.color.primary
                : strikePriceTheme.v2.color.onBackground,
          },
          alignment: "row-reverse",
          fontSize: actionsTheme.v2.typography.sizing.m,
          fontWeight: priceTheme.v2.typography.body.weight.heavy,
          color:
            props.mode === "small"
              ? actionsTheme.v2.color.primary
              : actionsTheme.v2.color.onBackground,
        },
      },
    };
  }

  getHeaderProps(theme) {
    const headerTheme = {v2: merge({}, theme.v2, get(theme.v2, "overrides.appBar"))};

    return {
      root: {
        backgroundColor: headerTheme.v2.color.surface,
        borderBottomColor: headerTheme.v2.color.onSurface,
        borderWidth: headerTheme.v2.decoration.border.width.xs,
        fontFamily: headerTheme.v2.typography.title6.family,
        color: headerTheme.v2.color.onSurface,
        fontWeight: headerTheme.v2.typography.title6.weight,
        fontSize: headerTheme.v2.typography.title6.size,
        letterSpacing: headerTheme.v2.typography.title6.letterSpacing,
        textTransform: headerTheme.v2.typography.title6.textCase,
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderCartButtonProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartButton")),
    };

    return {
      borderRadius: cartButtonTheme.v2.decoration.border.radius.default,
      highlightColor: theme.v2.color.primary,
      highlightTextColor: "#ffffff",
      color: cartButtonTheme.v2.color.primary,
    };
  }

  getHeaderCartBadgeProps(theme) {
    return {
      color: "#fff",
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.searchButton")),
    };

    return {
      borderRadius: searchButtonTheme.v2.decoration.border.radius.default,
      outlineColor: searchButtonTheme.v2.color.secondary,
      color: searchButtonTheme.v2.color.onSurface,
      highlightColor: searchButtonTheme.v2.color.primary,
    };
  }

  getHeaderLoginButtonProps(theme, props) {
    return {
      outlineColor: theme.v2.color.secondary,
      borderRadius: theme.v2.decoration.border.radius.default,
      padding: props.loginText !== "login" ? "10px" : "8px 24px", // only used if outline/highlight is set to true
      highlightColor: theme.v2.color.primary,
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.background,
        fontFamily: theme.v2.typography.title5.family,
        color: theme.v2.color.onSurface,
        fontWeight: theme.v2.typography.title5.weight,
        fontSize: {
          lg: theme.v2.typography.title5.size.lg,
          md: theme.v2.typography.bodyText2.size.md,
          sm: theme.v2.typography.caption2.size.sm,
        },
        locationText: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: {
            lg: theme.v2.typography.bodyText2.size.lg,
            md: theme.v2.typography.bodyText2.size.lg,
            sm: theme.v2.typography.bodyText2.size.md,
          },
        },
      },
    };
  }

  getDeliveryModePickerProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner.deliveryButton")),
    };

    const deliveryBannerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.deliveryBanner")),
    };

    return {
      backgroundColor: deliveryBannerTheme.v2.input?.color.surface,
      hoverSelectedBackgroundColor: theme.v2.active?.color.surface,
      color: theme.v2.active?.color.typography.default,
      pickupColor: deliveryBannerTheme.v2.color.primary,
      locationColor: deliveryBannerTheme.v2.input?.color.typography.default,
      borderRadius: theme.v2.active?.decoration.border.radius.default,
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      text: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.md,
          sm: theme.v2.typography.bodyText1.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
      },
      closeIcon: {
        color: theme.v2.color.onPrimary,
      },
    };
  }

  getSortFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const optionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.options")),
    };

    return {
      root: {
        padding: {
          lg: 0,
          md: 0,
        },
      },
      sort: {
        root: {
          width: {
            lg: "230px",
            sm: "190px",
          },
          control: {
            borderWidth: "1px",
            borderColor: theme.v2.color.border,
            borderRadius: theme.v2.decoration.border.radius.default,
            color: theme.v2.color.onSurface,
            backgroundColor: theme.v2.color.surface,
            fontFamily: theme.v2.typography.title5.family,
            fontSize: {
              lg: theme.v2.typography.title5.size.md,
            },
            fontWeight: theme.v2.typography.title5.weight,
            lineHeight: theme.v2.typography.title5.lineHeight,
            hover: {
              borderColor: theme.v2.color.primary,
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.default,
            backgroundColor: theme.v2.color.surface,
            borderColor: theme.v2.color.border,
            borderWidth: "1px",
            margin: `${theme.v2.static.spacing.size.xs} 0`,
          },
          options: {
            fontFamily: optionsTheme.v2.typography.title5.family,
            fontSize: {
              lg: optionsTheme.v2.typography.title5.size.md,
            },
            fontWeight: optionsTheme.v2.typography.title5.weight,
            hover: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
            selected: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
          },
          arrow: {
            borderColor: `${theme.v2.color.onSurface} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${theme.v2.color.onSurface}`,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: theme.v2.typography.title5.family,
            fontSize: theme.v2.typography.title5.size.md,
            borderRadius: theme.v2.decoration.border.radius.default,
            border: `1px solid ${theme.v2.color.border}`,
            fontWeight: theme.v2.typography.title5.weight,
            hoverBorderColor: theme.v2.color.primary,
          },
          secondary: {
            backgroundColor: theme.v2.color.surface,
            color: theme.v2.color.onSurface,
          },
        },
      },
    };
  }

  //NOT YET USED IN REFACTOR (USED ONLY IN CART)
  getProductRecommendationsProps(_theme) {
    return {
      element: {
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
        maxWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
    };
  }

  getModalProps(theme) {
    return {
      content: {
        borderRadius: {
          lg: theme.v2.decoration.border.radius.default,
          md: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
          sm: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
        },
      },
    };
  }

  getProductDetailProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail)};
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.secondaryTags")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };

    const quantityControllersTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.quantityControllers")
      ),
    };

    const addToCartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.addToCartButton")),
    };

    const divisorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.divisor")),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    const saleIndicatorTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.saleIndicator")),
    };

    const giftCardSelectorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.giftCardSelector")),
    };

    return {
      brand: {
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.sizing.m.lg"),
          md: get(brandNameTheme, "v2.typography.sizing.m.lg"),
          sm: get(brandNameTheme, "v2.typography.sizing.m.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.bodyText1.weight.default"),
        color: get(brandNameTheme, "v2.color.onPrimary"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
        textTransform: "uppercase",
      },
      name: {
        fontFamily: get(productNameTheme, "v2.typography.bodyText1.family"),
        fontSize: get(productNameTheme, "v2.typography.sizing.xl"),
        fontWeight: get(productNameTheme, "v2.typography.bodyText1.weight.default"),
        color: get(productNameTheme, "v2.color.onPrimary"),
        textTransform: get(productNameTheme, "v2.typography.bodyText1.textCase"),
      },
      separator: {
        color: get(divisorTheme, "v2.color.base.grey.200"),
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "10px 0 0 0",
      },
      flowerTypeIndicator: {
        padding: get(flowerTypeIndicatorTheme, "v2.static.spacing.size.md"),
        border: `0.5px solid ${flowerTypeIndicatorTheme.v2.color.base.grey[900]}`,
        borderRadius: get(flowerTypeIndicatorTheme, "v2.decoration.border.radius.xs"),
        fontSize: {
          lg: get(flowerTypeIndicatorTheme, "v2.typography.sizing.xs.lg"),
          md: get(flowerTypeIndicatorTheme, "v2.typography.sizing.xs.lg"),
          sm: get(flowerTypeIndicatorTheme, "v2.typography.sizing.xs.lg"),
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.body.weight.heavy"),
      },
      mainTags: {
        padding: {
          lg: get(potencyTagTheme, "v2.static.spacing.size.md"),
          md: get(potencyTagTheme, "v2.static.spacing.size.md"),
          sm: get(potencyTagTheme, "v2.static.spacing.size.md"),
        },
        border: `1px solid ${potencyTagTheme.v2.color.base.grey[200]}`,
        borderRadius: get(potencyTagTheme, "v2.decoration.border.radius.xs"),
        backgroundColor: get(potencyTagTheme, "v2.color.background"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
          md: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
          sm: get(potencyTagTheme, "v2.typography.sizing.xs.lg"),
        },
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText1.family"),
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText1.weight.default"),
        color: get(potencyTagTheme, "v2.color.onPrimary"),
      },
      saleIndicator: {
        position: "absolute",
        top: "18px",
        right: "60px",
        lineHeight: 0,
        border: `1px solid ${saleIndicatorTagTheme.v2.color.base.grey[200]}`,
        borderRadius: get(saleIndicatorTagTheme, "v2.decoration.border.radius.xs"),
        backgroundColor: "#e80c17",
        fontSize: {
          lg: get(saleIndicatorTagTheme, "v2.typography.sizing.xs.lg"),
          md: get(saleIndicatorTagTheme, "v2.typography.sizing.xs.lg"),
          sm: get(saleIndicatorTagTheme, "v2.typography.sizing.xs.lg"),
        },
        fontFamily: get(saleIndicatorTagTheme, "v2.typography.bodyText1.family"),
        fontWeight: get(saleIndicatorTagTheme, "v2.typography.bodyText1.weight.default"),
        color: get(saleIndicatorTagTheme, "v2.color.background"),
      },
      description: {
        root: {
          margin: `${theme.v2.spacing(4)} 0`,
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            md: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            sm: get(descriptionTheme, "v2.typography.sizing.m.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight.default"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            md: get(descriptionTheme, "v2.typography.sizing.m.lg"),
            sm: get(descriptionTheme, "v2.typography.sizing.m.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight.default"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
      },
      priceDisplay: {
        price: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
            md: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
            sm: get(priceDisplayTheme, "v2.typography.sizing.3xl.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight.heavy"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
            md: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
            sm: get(priceDisplayTheme, "v2.typography.sizing.xl.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 8px`,
          },
        },
        quantityPicker: {
          borderRadius: get(
            quantityControllersTheme,
            "v2.decoration.border.radius.default"
          ),
          backgroundColor: get(quantityControllersTheme, "v2.color.base.grey.200"),
          quantityColor: get(quantityControllersTheme, "v2.color.base.grey.500"),
          controllersColor: get(quantityControllersTheme, "v2.color.base.grey.500"),
          quantitySize: get(quantityControllersTheme, "v2.typography.title3.size"),
          controllersWidth: {
            lg: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
            md: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
            sm: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
          },
          controllersHeight: {
            lg: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
            md: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
            sm: get(quantityControllersTheme, "v2.static.spacing.size.lg"),
          },
          minusIconSize: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          plusIconSize: get(quantityControllersTheme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: "#f44336",
          },
        },
        addToCartButton: {
          backgroundColor: get(addToCartButtonTheme, "v2.color.primary"),
          color: get(addToCartButtonTheme, "v2.color.surface"),
          borderRadius: get(addToCartButtonTheme, "v2.decoration.border.radius.xs"),
          fontSize: get(addToCartButtonTheme, "v2.typography.sizing.l"),
          fontWeight: get(addToCartButtonTheme, "v2.typography.bodyText1.weight"),
          borderColor: get(addToCartButtonTheme, "v2.color.primary"),
        },
        price: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight.heavy"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },

        giftCardSelector: {
          selectedBackgroundColor: giftCardSelectorTheme.v2.color.primary,
          inputPlaceholderColor: giftCardSelectorTheme.v2.color.base.grey[400],
        },
      },
      secondaryTags: {
        backgroundColor: get(secondaryTagsTheme, "v2.color.base.grey.200"),
        color: get(secondaryTagsTheme, "v2.color.base.grey.600"),
        border: `1px solid ${get(secondaryTagsTheme, "v2.color.base.grey.300")}`,
        borderRadius: get(secondaryTagsTheme, "v2.decoration.border.radius.xl"),
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
        },
        fontWeight: get(secondaryTagsTheme, "v2.typography.bodyText1.weight.default"),
        textTransform: get(secondaryTagsTheme, "v2.text.case.capitalize"),
        padding: {
          lg: `${theme.v2.spacing(1)} ${theme.v2.spacing(2)}`,
          md: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
          sm: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: theme.v2.color.base.grey["200"],
    };
  }

  getFooterProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.footer)};
    return {
      trademark: {
        color: theme.v2.color.primary,
      },
    };
  }

  getCardProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        backgroundColor: theme.v2.color.background,
        borderRadius: theme.v2.decoration.border.radius.default,
        boxShadow: theme.v2.decoration.shadow.s
          ? applyShadow(theme.v2.decoration.shadow.s)
          : "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
        onHoverBoxShadow: applyShadow(theme.v2.decoration.shadow.s),
        hoverBorder: "0",
        border: "0",
      },
    };
  }

  getDealCardProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.dealCard)};

    return {
      root: {
        ...this.getCardProps(theme).root,
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "250px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      type: {
        fontSize: {
          lg: theme.v2.typography.sizing.xs.lg,
          md: theme.v2.typography.sizing.xs.md,
          sm: theme.v2.typography.sizing.xs.md,
        },
        fontWeight: theme.v2.typography.body.weight.medium,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: theme.v2.color.onBackground,
      },
      title: {
        fontSize: {
          lg: theme.v2.typography.sizing.m.lg,
          md: theme.v2.typography.sizing.m.md,
          sm: theme.v2.typography.sizing.m.md,
        },
        fontWeight: theme.v2.typography.body.weight.default,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: "#333333",
        lineHeight: theme.v2.typography.bodyText2.lineHeight,
      },
    };
  }

  getDealsListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.dealsList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealsList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealsList.title")),
    };

    const titleBackground = get(
      theme,
      "v2.overrides.dealsList.title.color.surface",
      "none"
    );

    return {
      root: {
        elementsPerRow: {
          lg: 5,
          md: 4,
          sm: 2,
        },
        padding: {
          lg: "0 32px",
          md: "0 16px",
          sm: "0 16px",
        },
      },
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "row",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px",
          sm: "368px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "170px",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onSurface,
          backgroundColor: titleBackground,
          lineHeight: titleTheme.v2.typography.title1.lineHeight,
          fontWeight: titleTheme.v2.typography.titles.weight.heavy,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: titleTheme.v2.typography.sizing["4xl"].lg,
            md: titleTheme.v2.typography.sizing.xxl.lg,
            sm: titleTheme.v2.typography.sizing.xxl.lg,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.bodyText1.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.sizing.l.lg,
              md: descriptionTheme.v2.typography.sizing.m.lg,
              sm: descriptionTheme.v2.typography.sizing.m.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight,
            color: descriptionTheme.v2.color.onSurface,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight.default,
            color: descriptionTheme.v2.color.onSurface,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "45px",
          backgroundColor: theme.v2.color.base.grey["300"],
          color: theme.v2.color.onBackground,
        },
      },
    };
  }

  getDealDetailProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.dealDetail)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealDetail.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealDetail.title")),
    };
    const typeTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealDetail.type")),
    };
    const disclaimerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealDetail.disclaimer")),
    };

    return {
      root: {
        height: {
          lg: "200px",
          md: "200px",
          sm: "400px",
        },
        padding: {
          lg: "16px",
          md: "16px",
          sm: "16px",
        },
        borderRadius: {
          lg: theme.v2.decoration.border.radius.default,
          md: theme.v2.decoration.border.radius.default,
          sm: theme.v2.decoration.border.radius.default,
        },
        boxShadow: theme.v2.decoration.shadow.s
          ? applyShadow(theme.v2.decoration.shadow.s)
          : "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
        border: "0",
        backgroundColor: theme.v2.color.background,
      },
      image: {
        height: {
          lg: "200px",
          md: "200px",
          sm: "200px",
        },
        width: {
          lg: "300px",
          md: "300px",
          sm: "256px",
        },
      },
      textContainer: {
        margin: {
          lg: "0 0 0 16px",
          md: "0 0 0 16px",
          sm: "0 0 0 0",
        },
        rowGap: {
          lg: "16px",
          md: "16px",
          sm: "16px",
        },
      },
      type: {
        fontSize: {
          lg: typeTheme.v2.typography.sizing.l.lg,
          md: typeTheme.v2.typography.sizing.l.md,
          sm: typeTheme.v2.typography.sizing.l.sm,
        },
        fontWeight: typeTheme.v2.typography.body.weight.default,
        fontFamily: typeTheme.v2.typography.title1.family,
        color: typeTheme.v2.color.onBackground,
        textTransform: "uppercase",
      },
      title: {
        fontSize: {
          lg: titleTheme.v2.typography.sizing["3xl"].lg,
          md: titleTheme.v2.typography.sizing["3xl"].md,
          sm: titleTheme.v2.typography.sizing["3xl"].sm,
        },
        fontWeight: titleTheme.v2.typography.body.weight.heavy,
        fontFamily: titleTheme.v2.typography.title1.family,
        color: titleTheme.v2.color.onBackground,
      },
      description: {
        text: {
          fontSize: {
            lg: descriptionTheme.v2.typography.sizing.m.lg,
            md: descriptionTheme.v2.typography.sizing.m.md,
            sm: descriptionTheme.v2.typography.sizing.m.sm,
          },
          fontWeight: descriptionTheme.v2.typography.body.weight.default,
          fontFamily: descriptionTheme.v2.typography.bodyText1.family,
          color: descriptionTheme.v2.color.onBackground,
        },
      },
      disclaimer: {
        fontSize: {
          lg: disclaimerTheme.v2.typography.sizing.m.lg,
          md: disclaimerTheme.v2.typography.sizing.m.md,
          sm: disclaimerTheme.v2.typography.sizing.m.sm,
        },
        fontWeight: disclaimerTheme.v2.typography.body.weight.default,
        fontFamily: disclaimerTheme.v2.typography.bodyText1.family,
        color: "#00000080",
      },
      bogoButton: {
        root: {
          border: `1px solid ${theme.v2.color.primary}`,
          borderRadius: theme.v2.decoration.border.radius.default,
          fontFamily: theme.v2.typography.bodyText2.family,
        },
        secondary: {
          backgroundColor: theme.v2.color.primary,
        },
      },
    };
  }

  getBundleListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.bundleList)};

    return {
      root: {
        elementsPerRow: {
          lg: 6,
          md: 4,
          sm: 1,
        },
        padding: {
          lg: "0 32px",
          md: "0 16px",
          sm: "0 16px",
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: theme.v2.typography.title1.family,
          color: theme.v2.color.base.grey[800],
          backgroundColor: "transparent",
          fontWeight: theme.v2.typography.titles.weight.heavy,
          textTransform: theme.v2.text.decorations.none,
          fontSize: {
            lg: theme.v2.typography.sizing["3xl"].lg,
            md: theme.v2.typography.sizing["3xl"].lg,
            sm: theme.v2.typography.sizing.l.lg,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
      },
    };
  }

  getBundleCardProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };

    return {
      root: {
        ...this.getCardProps(theme).root,
        height: {
          lg: "175px",
          md: "175px",
          sm: "175px",
        },
        maxWidth: {
          lg: "280px",
          md: "25vw",
          sm: "50vw",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
      },
      image: {
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: "175px",
          md: "175px",
          sm: "175px",
        },
        objectFit: "contain",
        padding: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
      flowerTypeIndicator: {
        margin: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
    };
  }

  getShowcasedProductListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.title")),
    };

    const titleBackground = get(
      theme,
      "v2.overrides.showcasedProductsList.title.color.surface",
      "none"
    );

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "row",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "320px",
          sm: "320px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "50%",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onSurface,
          backgroundColor: titleBackground,
          lineHeight: titleTheme.v2.typography.title1.lineHeight,
          fontWeight: titleTheme.v2.typography.titles.weight.heavy,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: titleTheme.v2.typography.sizing["4xl"].lg,
            md: titleTheme.v2.typography.sizing.xxl.lg,
            sm: titleTheme.v2.typography.sizing.xxl.lg,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.bodyText1.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.sizing.l.lg,
              md: descriptionTheme.v2.typography.sizing.m.lg,
              sm: descriptionTheme.v2.typography.sizing.m.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight,
            color: descriptionTheme.v2.color.onSurface,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight.default,
            color: descriptionTheme.v2.color.onSurface,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getProductListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.title")),
    };

    return {
      root: {
        elementsPerRow: {
          lg: 6,
          md: 4,
          sm: 2,
        },
        padding: {
          lg: "0 32px",
          md: "0 16px",
          sm: "0 16px",
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onSurface,
          backgroundColor: titleTheme.v2.color.surface,
          fontWeight: titleTheme.v2.typography.titles.weight.heavy,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: titleTheme.v2.typography.sizing["4xl"].lg,
            md: titleTheme.v2.typography.sizing.xxl.lg,
            sm: titleTheme.v2.typography.sizing.xxl.lg,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.bodyText1.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.sizing.l.lg,
              md: descriptionTheme.v2.typography.sizing.m.lg,
              sm: descriptionTheme.v2.typography.sizing.m.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight,
            color: descriptionTheme.v2.color.onSurface,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight.default,
            color: descriptionTheme.v2.color.onSurface,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getRecommendedListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.recommendedList)};
    return {
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
      header: {
        viewAllColor: theme.v2.color.onSurface,
        backgroundColor: theme.v2.color.background,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.xxl.lg,
        fontWeight: theme.v2.typography.body.weight.heavy,
        fontFamily: theme.v2.typography.body.family,
        skewness: theme.v2.decoration.textSkewness || 0,
        padding: "0 20px",
      },
    };
  }

  getShowcaseRecommendedProductsListProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.showcaseRecommendedProductsList),
    };
    const titleTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.showcaseRecommendedProductsList.title")
      ),
    };
    const titleBackground = get(
      theme,
      "v2.overrides.showcaseRecommendedProductsList.title.color.surface",
      "none"
    );
    const descriptionTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.showcaseRecommendedProductsList.text")
      ),
    };
    return {
      container: {
        color: theme.v2.color.base.grey[200],
      },
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "row",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "320px",
          sm: "320px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "50%",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onSurface,
          backgroundColor: titleBackground,
          lineHeight: titleTheme.v2.typography.title1.lineHeight,
          fontWeight: titleTheme.v2.typography.titles.weight.heavy,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: titleTheme.v2.typography.sizing["4xl"].lg,
            md: titleTheme.v2.typography.sizing.xxl.lg,
            sm: titleTheme.v2.typography.sizing.xxl.lg,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.bodyText1.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.sizing.l.lg,
              md: descriptionTheme.v2.typography.sizing.m.lg,
              sm: descriptionTheme.v2.typography.sizing.m.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight,
            color: descriptionTheme.v2.color.onSurface,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight.default,
            color: descriptionTheme.v2.color.onSurface,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  //NOT YET USED (USED IN FILTERS DRAWER)
  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.default,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary || "#F0F0F0",
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.default,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0, //add token to differentiate from flight
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
      },
    };
  }

  getTitleProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.title)};

    return {
      fontSize: theme.v2.typography.sizing["4xl"].lg,
      lineHeight: "32px",
      color: "#020202",
      margin: 0,
      textAlign: "start",
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getSubcategoriesProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: theme.v2.typography.bodyText1.size,
        fontWeight: theme.v2.typography.bodyText1.weight,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      selected: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      unselected: {
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
      },
    };
  }
}

memoizeClassMethods(DefaultThemeProps);

export default DefaultThemeProps;
