import {PaymentOptions} from "src/core/payments/constants";
import Aeropay from "src/core/payments/aeropay/Aeropay";
import {BankPaymentSource} from "src/core/payments/models/paymentSource";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const AeropayPaymentForm = suspensify(
  lazy(() => import("src/core/payments/aeropay/AeropayForm"))
);

export default {
  type: PaymentOptions.AEROPAY,
  PaymentClass: Aeropay,
  PaymentForm: AeropayPaymentForm,
  PaymentSource: {
    class: BankPaymentSource,
    options: {
      retryLabel: "Retry",
      displayConvenienceFee: true,
    },
  },
};
