import * as PaymentsAPI from "src/core/api/payments";
import autoBind from "auto-bind";
import OnlinePayment from "src/core/payments/OnlinePayment";
import {formatPrice} from "src/core/common/price";
import {PaymentOptions} from "src/core/payments/constants";
import {makePaymentSource} from "src/core/payments/factories/paymentSource";

class Aeropay extends OnlinePayment {
  constructor(code) {
    super(code);

    autoBind(this);
  }

  async pay(orderId, paymentData) {}

  async getConfiguration() {
    return PaymentsAPI.getConfiguration(PaymentOptions.AEROPAY);
  }

  async preparePayment(orderInfo, paymentData, cartId) {
    const openWidget = await import("aerosync-web-sdk").then(mod => mod.openWidget);

    const customer = await PaymentsAPI.createCustomer(PaymentOptions.AEROPAY, cartId);
    const token = customer?.data?.attributes?.extra_content?.token;

    await this.getConfiguration();

    return new Promise((resolve, reject) => {
      const element = this.getWidgetElement();
      let addingPaymentSource = false;

      let widgetRef = openWidget({
        id: element.id,
        iframeTitle: "Connect",
        environment: this.isProduction() ? "production" : "staging",
        token: token,
        zIndex: 2,
        style: {
          width: "375px",
          height: "688px",
          bgColor: "#000000",
          opacity: 0.7,
        },
        onSuccess: async event => {
          const userInfo = {user_id: event.user_id, user_password: event.user_password};
          const customerToken = btoa(JSON.stringify(userInfo));
          try {
            addingPaymentSource = true;
            const paymentSource = await this.addPaymentSource(customerToken);
            const result = {
              ...orderInfo,
              payment_specification: {payment_source_id: paymentSource.id},
            };
            resolve(result);
          } catch (e) {
            reject(e);
          } finally {
            addingPaymentSource = false;
          }
        },
        onClose: function () {
          if (!addingPaymentSource) {
            reject();
          }
        },
        onError: function (event) {
          console.error("onError", event);
        },
      });

      // launch Aerosync widget with the configuration
      widgetRef.launch();
    });
  }

  getWidgetElement() {
    let element = document.getElementById("aeropay-widget");
    if (!element) {
      element = document.createElement("div");
      element.id = "aeropay-widget";
      document.body.appendChild(element);
    }
    return element;
  }

  async addPaymentSource(token) {
    const response = await PaymentsAPI.addPaymentSource(PaymentOptions.AEROPAY, {
      payment_source_token: token,
    });
    await PaymentsAPI.paymentSources(PaymentOptions.AEROPAY);
    return makePaymentSource(response.data, PaymentOptions.AEROPAY);
  }

  async getPaymentDisclaimer({shop, cart, order}) {
    const prices = cart ? cart.getPrices() : order ? order.getPrices() : null;
    if (!shop || !prices) return "";
    return `By clicking pay I authorize ${shop.getName()} to debit ${formatPrice(
      prices.totalPrice
    )} from my account.`;
  }

  getToken = async () => {
    const response = await PaymentsAPI.getToken(PaymentOptions.AEROPAY);
    return response.data?.attributes?.token;
  };
}

export default Aeropay;
